import { useStaticQuery, graphql } from 'gatsby'

export const usePolicyLinkItems = () =>
  useStaticQuery(graphql`
    query {
      globalFooterEn: sanityGlobalFooter(i18n_lang: {eq: "en"}) {
        policyLinks {
          items {
            url
            title
          }
        }
      }
      globalFooterIt: sanityGlobalFooter(i18n_lang: {eq: "it"}) {
        policyLinks {
          items {
            url
            title
          }
        }
      }
    }
  `)

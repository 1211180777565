import { Link } from 'gatsby'
// import PropTypes from 'prop-types'
import React from 'react'

import { usePolicyLinkItems } from './PolicyLinkItems'

const LowerFooter = () => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const data = usePolicyLinkItems()
  const { items } = language === 'en' ? data.globalFooterEn.policyLinks : data.globalFooterIt.policyLinks 

  const d = new Date();
  const year = d.getFullYear();

  return (
    <div className="justify-center px-4 pt-8 pb-12 bg-white text-14 sm:flex">
      <ul className="flex justify-center mb-2">
        {items.map((item, index) => {
          let link = item.url

          if (link) {
            if (!item.url.endsWith('/')) {
              link = `${item.url}/`
            }
          }

          return (
            <li key={index} className="px-5 border-r border-gray-300">
              <Link to={link} className="text-blue-500 hover:text-underline">
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
      <p className="text-center sm:px-5">&copy; {year} Soul Valley</p>
    </div>
  )
}

LowerFooter.propTypes = {}

LowerFooter.defaultProps = {}

export default LowerFooter
